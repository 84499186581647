@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --background-color: rgb(194, 150, 100);
    --font-navbar-color: rgb(107, 69, 19);
}


/* App ------------------------------------------------------------------ */

.app {
    min-height: 93vh;
    width: 100vw;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    color: white;
}

@media screen and (min-width: 1281px) {
    .app {
        display: none;
    }
}


.ligne {
    display: flex;
    height: calc(100% / 4);
}

.case {
    display: flex;
    width: 50%;
    height: 100%;
    color: white;
}

.case:hover {
    color: white;
    text-decoration: none;
}

.ecurie {
    margin: auto;
}


.casaque {
    width: 10vh;
    margin-bottom: 15%;
    filter: drop-shadow(15px 4px 4px rgb(109, 109, 109));
}

.casaque:hover {
    transition: transform 500ms;
    transform: rotate(360deg);
}

.nomEcurie {
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    font-family: 'Hind Siliguri', sans-serif;
}


/* --- min-width: 1281px */

.app2 {
    display: none;
}

@media screen and (min-width: 1281px) {
    .app2 {
        height: 93vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    color: white;
    background-color: var(--background-color);
    }
}

.ligne2 {
    display: flex;
    width: 100vw;
    height: 50%;
}

.case2 {
    display: flex;
    width: 20vw;
    height: 100%;
    color: white;
}

.case2:hover {
    color: white;
    text-decoration: none;
}




/* Accueil 2013 ----------------------------------------------------------------- */

.accueil2013 {
    min-height: 93vh;
    width: 100%;
    background-color: var(--background-color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
    text-align: -webkit-center;
}

.titreAccueil {
    text-align: center;
    padding: 2% 0 2% 0;
}

.annee {
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Hind Siliguri', sans-serif;
}

.casaqueAccueil {
    width: 50px;
    filter: drop-shadow(15px 4px 4px rgb(109, 109, 109));
}

.rubrique {
    border-radius: 5px;
    width: 70%;
    height: 10vh;
    margin-left: 5%;
    display: flex;
    margin-bottom: 2%;
    color: white;
    background-color: rgb(165, 117, 62);
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

.border-black {
    border: solid 1px black;
}

.border-white {
    border: solid 1px white;
}

.rubrique:hover {
    color: black;
    text-decoration: none;
    background-color: rgb(214, 208, 208);
}

.titreRubrique {
    margin: auto;
    text-align: center;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: bold;
}


/* Bilan ----------------------------------------------------------------------- */

.divBilan {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 93vh;
    padding-bottom: 10%;
    background-color: var(--background-color);
    font-family: 'Lato', sans-serif;
    color: white;
}

.result {
    margin: auto;
    margin: 4% 0;
    color: black;
    font-size: 0.8em;
}

@media screen and (min-width: 1025px) {
    .result {
        margin: auto;
    }
}

.total {
    background-color : rgb(167, 114, 53);
    margin :0;
    padding : 2% 0;
    color: white;
    font-size: 1em;
}

.pReussite {
    margin: 0;
    text-align: center;
}

.reussite {
    text-align: center;
    font-style: italic;
    border-radius: 5px;
    margin: 0;
}

.span_casaque_bilan {
    padding-right : 2vw;
}

.casaque_bilan {
    width: 4vw;
}

@media screen and (min-width: 1025px) {
    .casaque_bilan {
        width: 2vw;
    }
}



/* Compo ----------------------------------------------------------------------- */

.cheval {
    border: solid 1px white;
    border-radius: 5px;
    width: 90%;
    height: 25vh;
    margin-left: 5%;
    display: flex;
    margin-bottom: 2%;
    color: white;
    display: flex;
    background-color: rgb(165, 117, 62);
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .cheval {
        width: 70%;
        height: 50vh;
        margin: auto;
        margin-bottom: 2%;
    }
}

.imgHorizontale {
    width: 50%;
    height: 100%;
}

.imgVerticale {
    width: 30%;
    height: 100%;
}

.infos {
    width: 50%;
    margin: auto;
    font-size: 0.6em;
    font-family: 'Lato', sans-serif;
}

@media screen and (min-width: 600px) {
    .infos {
        font-size: 1.5em;
    }
}

.nomCheval {
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    margin: 0;
}

@media screen and (min-width: 768px) {
    .nomCheval {
        font-size: 1.8rem;
    }
}

.discipline {
    font-style: italic;
    text-align: center;
    font-size: 0.8rem;
}

@media screen and (min-width: 768px) {
    .discipline {
        font-size: 1.2rem;
    }
}

.divDetailsCompo {
    display: flex;
    flex-direction: row;
}

.resultats {
    display: flex;
    width: 50%;
}

.resultats2 {
    margin: auto;
    padding-left: 5%;
}

@media screen and (min-width: 1024px) {
    .divInfos {
        padding-right: 2%;
    }
}

.pInfos {
    text-align: center;
    margin: 0;
    font-size: 0.5rem;
}

@media screen and (min-width: 768px) {
    .pInfos {
        font-size: 1.2rem;
    }
}


/* Contact ------------------------------------------------------------------------ */

.divContact {
    display: flex;
    height: 93vh;
    background-color: var(--background-color);
    font-family: 'Lato', sans-serif;
}

.divContact2 {
    margin: auto;
    color: white;
}

.divContact2 a {
    color: white;
}

.divContact2 a:hover {
    color: white;
}

.textContact {
    font-size: 0.9rem;
}

@media screen and (min-width: 540px) {
    .textContact {
        font-size: 1.2rem;
    }
}


/* Crédits ------------------------------------------------------------------------ */

.divCredits {
    display: flex;
    height: 93vh;
    background-color: var(--background-color);
    font-family: 'Lato', sans-serif;
}

.divCredits2 {
    margin: auto;
    color: white;
}

.divCredits2 a {
    color: white;
}

.divCredits2 a:hover {
    color: white;
}


/* Footer ------------------------------------------------------------------------- */

.bodyFooter {
    height: 7vh;
    width: 100vw;
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 5;
}

@media screen and (min-width: 1025px) {
    .bodyFooter {
        display: none;
    }
}

.divFooter {
    width: calc(100% / 6);
    height: 7vh;
    display: flex;
    background-color: var(--background-color);
    border-top: solid 1px white;
    border-left: solid 1px white;
    border-right: solid 1px white;
}

.ecStyle {
    background-color: rgb(146, 93, 43);
}

.linkFooter {
    margin: auto;
    color: white;
    height: 100%;
}

.stable {
    width: 100%;
    height: 7vh;
}

.colorLinkFooter {
    font-size: 0.7em;
    height: 100%;
    display: flex;
}

@media screen and (min-width: 760px) {
    .colorLinkFooter {
        font-size: 1.4em;
    }
}

.casaqueFooter {
    margin: auto;
    height: 80%;
    filter: drop-shadow(10px 4px 4px rgb(109, 109, 109));
}


/* Header --------------------------------------------------------------------- */

.bodyHeader {
    display: none;
}

@media screen and (min-width: 1025px) {
    .bodyHeader {
        display: block;
    }
}

.navbar {
    height: 7vh;
    z-index: 100 !important;
    background-color: var(--background-color);
    border-bottom: solid 1px white;
    color: white !important;
}

.nav-link {
    background-color: var(--background-color);
    z-index: 100 !important;
    color: var(--font-navbar-color) !important;
}


/* HeaderMobile -------------------------------------------------------------- */

.bodyHeaderMobile {
    height: 7vh;
    width: 100vw;
    display: flex;
    top: 0;
}

@media screen and (min-width: 1025px) {
    .bodyHeaderMobile {
        display: none;
    }
}

.divHeaderMobile {
    width: 100%;
    height: 7vh;
    display: flex;
    background-color: var(--background-color);
    border-bottom: solid 1px white;
}

.logo {
    width: 55%;
}

.horse {
    height: 6vh;
}

.linkHeaderMobile3 {
    color: white;
    width: 10%;
    line-height: 7vh;
    text-align: right;
    font-size: 1rem;
    font-family: 'Parisienne', cursive, sans-serif;
    font-weight: bold;
    color: var(--font-navbar-color);
}

.linkHeaderMobile3:hover {
    color: white;
    text-decoration: none;
}

@media screen and (min-width: 540px) {
    .linkHeaderMobile3 {
        font-size: 1rem;
    }
}

.linkHeaderMobile1 {
    color: white;
    width: 15%;
    line-height: 7vh;
    text-align: right;
    font-size: 1rem;
    font-family: 'Parisienne', cursive, sans-serif;
    font-weight: bold;
    color: var(--font-navbar-color);
}

.linkHeaderMobile1:hover {
    color: white;
    text-decoration: none;
}

@media screen and (min-width: 540px) {
    .linkHeaderMobile1 {
        font-size: 1rem;
    }
}

.linkHeaderMobile2 {
    color: white;
    width: 20%;
    line-height: 7vh;
    text-align: center;
    font-size: 1rem;
    font-family: 'Parisienne', cursive, sans-serif;
    font-weight: bold;
    color: var(--font-navbar-color);
}

.linkHeaderMobile2:hover {
    color: white;
    text-decoration: none;
}

@media screen and (min-width: 540px) {
    .linkHeaderMobile2 {
        font-size: 1rem;
    }
}

.linkHeaderMobile4 {
    color: white;
    width: 10%;
    line-height: 7vh;
    text-align: center;
    font-size: 1rem;
    font-family: 'Parisienne', cursive, sans-serif;
    font-weight: bold;
    color: var(--font-navbar-color);
}

.linkHeaderMobile4:hover {
    color: white;
    text-decoration: none;
}

@media screen and (min-width: 540px) {
    .linkHeaderMobile4 {
        font-size: 1rem;
    }
}


/* Hippodromes -------------------------------------------------------------- */

.accueilHippodrome {
    min-height: 93vh;
    width: 100%;
    background-color: var(--background-color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
    text-align: -webkit-center;
}

.titreAccueilHippodrome {
    text-align: center;
    padding: 2% 0 2% 0;
}

.regionHippodrome {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Hind Siliguri', sans-serif;
    padding-top: 2vh;
}

@media screen and (min-width: 600px) {
    .regionHippodrome {
        font-size: 2em;
        padding-top: 0;
    }
}

.nomListe {
    font-size: 1.5em;
}

.hippodrome {
    border: solid 1px white;
    border-radius: 5px;
    width: 90%;
    height: 25vh;
    margin-left: 5%;
    display: flex;
    margin-bottom: 2%;
    color: white;
    display: flex;
    background-color: rgb(165, 117, 62);
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .hippodrome {
        width: 70%;
        height: 50vh;
        margin: auto;
        margin-bottom: 2%;
    }
}


.divHippodromes {
    min-height: 93vh;
    width: 100%;
    background-color: var(--background-color);
    color: white;
    display: flex;
    flex-direction: column;
    padding: 2vh 0 10vh 0;
    text-align: -webkit-center;
}

.infosHippodromes {
    width: 50%;
    margin: auto;
    font-size: 0.6em;
    font-family: 'Lato', sans-serif;
}

@media screen and (min-width: 600px) {
    .infosHippodromes {
        font-size: 1.5em;
    }
}

.nomHippodrome {
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    margin: 0;
}

@media screen and (min-width: 768px) {
    .nomHippodrome {
        font-size: 1.8rem;
    }
}

@media screen and (min-height: 668px) {
    .nomHippodrome {
        padding-bottom: 2%;
    }
}

.pInfosHippodrome {
    text-align: left;
    margin: 0;
    font-size: 0.5rem;
    padding-left: 5%;
}

@media screen and (min-width: 768px) {
    .pInfosHippodrome {
        font-size: 1.2rem;
    }
}

.divHippodrome {
    padding-bottom: 0%;
}

@media screen and (min-width: 768px) {
    .divHippodrome {
        padding-bottom: 0%;
    }
}




/* Jo Way de Nappes -------------------------------------------------------------- */

.accueilJo {
    min-height: 93vh;
    width: 100%;
    background-color: var(--background-color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
    text-align: -webkit-center;
}

.titreAccueilJo {
    text-align: center;
    padding: 2% 0 2% 0;
}

.casaqueAccueilJo {
    width: 50px;
    filter: drop-shadow(15px 4px 4px rgb(109, 109, 109));
}


.anneeJo {
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Hind Siliguri', sans-serif;
}

.casaqueAccueil {
    width: 50px;
    filter: drop-shadow(15px 4px 4px rgb(109, 109, 109));
}

.imgJo {
    text-align: center;
}

.jo01 {
    width: 60vw;

}

@media screen and (min-width: 768px) {
    .jo01 {
        width: 30vw;
    }
}



/* Not Found -------------------------------------------------------------- */

.notFound {
    height: 93vh;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.divNotFound {
    margin: auto;
    color: white;
}

.imgExit {
    width: 100px;
}

.imgDoor {
    width: 100px;
}


/* Page ----------------------------------------------------------------------------------- */

.accueilPremierPartantGalop {
    min-height: 93vh;
    width: 100%;
    background-color: var(--background-color);
    color: white;
    padding-bottom: 10vh;
    font-family: 'Lato', sans-serif;
}

.titrePage {
    text-align: center;
    font-weight: bold;
    padding-top: 2%;
}

.infosPage {
    text-align: center;
}

.photosPage {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1025px) {
    .photosPage {
        flex-direction: row;
    }
}

.divPhotoPage {
    margin: auto;
    margin-bottom: 2%;
}

.photoPageHorizontale {
    width: 80vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageHorizontale {
        width: 30vw;
    }
}

.photoBelle {
    width: 60vw;
}

@media screen and (min-width: 1025px) {
    .photoBelle {
        width: 25vw;
    }
}

.photoPageVerticale {
    width: 40vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageVerticale {
        width: 18vw;
    }
}
:root {
    --backgroundLady-color: rgb(71, 70, 68);
    --backgroundLady-color2: rgb(31, 30, 28);
}


/* Acueil Lady ------------------------------------------------------------------ */

.accueilLady {
    height: 100vh;
    width: 100vw;
    background-color: var(--backgroundLady-color);
    color: white;
}

.accueilApp {
  position: absolute;
  top: 1%;
  left: 1%;  
}

.horseLady {
    height: 6vh;
}

.titreAccueilLady {
    font-family: 'Dancing Script', cursive;
    height: 15%;
    position: relative;
}

.titreAccueilLady h1 {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;;
}

.menu {
    height: 85%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.divMenu {
    height: 50%;
    width: 50%;
    display: flex;
}

.linkImg {
    margin: auto;
    text-align: center;
}

.img_ecurie_lady {
    width: 90%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_ecurie_lady {
        width: 55%;
    }
}

.img_entrainement_lady {
    width: 80%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_entrainement_lady {
        width: 55%;
    }
}

.img_courses_lady {
    width: 80%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_courses_lady {
        width: 65%;
    }
}

.img_fiche_lady {
    width: 60%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_fiche_lady {
        width: 25%;
    }
}



/* Lady écurie ------------------------------------------------------------------ */

.accueilPremierPartantGalop2 {
    min-height: 93vh;
    width: 100%;
    background-color: var(--backgroundLady-color);
    color: white;
    padding-bottom: 10vh;
    font-family: 'Lato', sans-serif;
}

.retour {
    position: absolute;
    top: 1%;
    right: 1%;  
    color: white;
    font-size: 1em;
  }

.titreLady {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    padding: 5% 0 5% 0;
}

.titreLadyFiche {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    padding: 5% 0 5% 0;
}

@media screen and (min-width: 1025px) {
    .titreLadyFiche {
        padding: 5% 0 1% 0;
    }
}

.infosPage {
    text-align: center;
}

.photosPage {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1025px) {
    .photosPage {
        flex-direction: row;
    }
}

.divPhotoPage {
    margin: auto;
    margin-bottom: 2%;
}

.photoPageHorizontale {
    width: 80vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageHorizontale {
        width: 30vw;
    }
}

.photoPageVerticale {
    width: 40vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageVerticale {
        width: 18vw;
    }
}

.infosLady {
    color: white;
    text-align: center;
    padding: 5% 0 5% 0;
}

@media screen and (min-width: 1025px) {
    .infosLady {
        padding: 1% 0 1% 0;
    }
}

.photoPageVerticaleFiche {
    width: 70vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageVerticaleFiche {
        width: 34vw;
    }
}



/* Lady courses ------------------------------------------------------------------ */

.accueilLadyCourses {
    min-height: 100vh;
    width: 100%;
    background-color: var(--backgroundLady-color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
    text-align: -webkit-center;
}


.rubriqueLady {
    border-radius: 5px;
    width: 70%;
    height: 10vh;
    margin-left: 5%;
    display: flex;
    margin-bottom: 2%;
    color: white;
    background-color: var(--backgroundLady-color2);
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}


.rubriqueLady:hover {
    color: black;
    text-decoration: none;
    background-color: rgb(214, 208, 208);
}

.titreRubriqueLady {
    margin: auto;
    text-align: center;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: bold;
}

:root {
    --backgroundKaleotic-color: rgb(29, 27, 22);
    --backgroundKaleotic-color2: rgb(88, 85, 78);
}


/* Acueil Kaleotic ------------------------------------------------------------------ */

.accueilKaleotic {
    height: 100vh;
    width: 100vw;
    background-color: var(--backgroundKaleotic-color);
    color: white;
}

.accueilApp {
  position: absolute;
  top: 1%;
  left: 1%;  
}

.horseKaleotic {
    height: 6vh;
}

.titreAccueilKaleotic {
    font-family: 'Dancing Script', cursive;
    height: 15%;
    position: relative;
}

.titreAccueilKaleotic h1 {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;;
}

.menu {
    height: 85%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.divMenu {
    height: 50%;
    width: 50%;
    display: flex;
}

.linkImg {
    margin: auto;
    text-align: center;
}

.img_ecurie_kaleotic {
    width: 90%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_ecurie_kaleotic {
        width: 45%;
    }
}

.img_entrainement_kaleotic {
    width: 75%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_entrainement_kaleotic {
        width: 45%;
    }
}

.img_courses_kaleotic {
    width: 88%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_courses_kaleotic {
        width: 55%;
    }
}

.img_fiche_kaleotic {
    width: 70%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_fiche_kaleotic {
        width: 45%;
    }
}



/* Kaleotic écurie ------------------------------------------------------------------ */

.accueilPremierPartantGalop2 {
    min-height: 93vh;
    width: 100%;
    background-color: var(--backgroundKaleotic-color);
    color: white;
    padding-bottom: 10vh;
    font-family: 'Lato', sans-serif;
}

.retour {
    position: absolute;
    top: 1%;
    right: 1%;  
    color: white;
    font-size: 1em;
  }

.titreKaleotic {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    padding: 5% 0 5% 0;
    font-size: 2.2rem;
}

.titreKaleoticEntrainement {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-size: 2rem;
    padding: 5% 0 5% 0;
}

.titreKaleoticFiche {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    padding: 5% 0 5% 0;
}

@media screen and (min-width: 1025px) {
    .titreKaleoticFiche {
        padding: 5% 0 1% 0;
    }
}

.infosPage {
    text-align: center;
}

.photosPage {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1025px) {
    .photosPage {
        flex-direction: row;
    }
}

.divPhotoPage {
    margin: auto;
    margin-bottom: 2%;
}

.photoPageHorizontale {
    width: 80vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageHorizontale {
        width: 30vw;
    }
}

.photoPageVerticale {
    width: 40vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageVerticale {
        width: 18vw;
    }
}

.infosKaleotic {
    color: white;
    text-align: center;
    padding: 5% 0 5% 0;
}

@media screen and (min-width: 1025px) {
    .infosKaleotic {
        padding: 1% 0 1% 0;
    }
}

/* Kaleotic courses ------------------------------------------------------------------ */

.accueilKaleoticCourses {
    min-height: 100vh;
    width: 100%;
    background-color: var(--backgroundKaleotic-color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
    text-align: -webkit-center;
}

.titreKaleoticCourses {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    padding: 5% 0 0 0;
}

.titreKaleoticCourses2 {
    text-align: center;
    font-family: 'Hind Siliguri', cursive;
    margin: 0;
}


.titreKaleoticCourses3 {
    text-align: center;
    font-family: 'Hind Siliguri', cursive;
    padding: 0% 0 5% 0;
}

.rubriqueKaleotic {
    border-radius: 5px;
    width: 70%;
    height: 10vh;
    margin-left: 5%;
    display: flex;
    margin-bottom: 2%;
    color: white;
    background-color: var(--backgroundKaleotic-color2);
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}


.rubriqueKaleotic:hover {
    color: black;
    text-decoration: none;
    background-color: rgb(214, 208, 208);
}

.titreRubriqueKaleotic {
    margin: auto;
    text-align: center;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: bold;
}

