@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

:root {
    --backgroundKaleotic-color: rgb(29, 27, 22);
    --backgroundKaleotic-color2: rgb(88, 85, 78);
}


/* Acueil Kaleotic ------------------------------------------------------------------ */

.accueilKaleotic {
    height: 100vh;
    width: 100vw;
    background-color: var(--backgroundKaleotic-color);
    color: white;
}

.accueilApp {
  position: absolute;
  top: 1%;
  left: 1%;  
}

.horseKaleotic {
    height: 6vh;
}

.titreAccueilKaleotic {
    font-family: 'Dancing Script', cursive;
    height: 15%;
    position: relative;
}

.titreAccueilKaleotic h1 {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;;
}

.menu {
    height: 85%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.divMenu {
    height: 50%;
    width: 50%;
    display: flex;
}

.linkImg {
    margin: auto;
    text-align: center;
}

.img_ecurie_kaleotic {
    width: 90%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_ecurie_kaleotic {
        width: 45%;
    }
}

.img_entrainement_kaleotic {
    width: 75%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_entrainement_kaleotic {
        width: 45%;
    }
}

.img_courses_kaleotic {
    width: 88%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_courses_kaleotic {
        width: 55%;
    }
}

.img_fiche_kaleotic {
    width: 70%;
    border-radius: 5px;
}

@media screen and (min-width: 1025px) {
    .img_fiche_kaleotic {
        width: 45%;
    }
}



/* Kaleotic écurie ------------------------------------------------------------------ */

.accueilPremierPartantGalop2 {
    min-height: 93vh;
    width: 100%;
    background-color: var(--backgroundKaleotic-color);
    color: white;
    padding-bottom: 10vh;
    font-family: 'Lato', sans-serif;
}

.retour {
    position: absolute;
    top: 1%;
    right: 1%;  
    color: white;
    font-size: 1em;
  }

.titreKaleotic {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    padding: 5% 0 5% 0;
    font-size: 2.2rem;
}

.titreKaleoticEntrainement {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-size: 2rem;
    padding: 5% 0 5% 0;
}

.titreKaleoticFiche {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    padding: 5% 0 5% 0;
}

@media screen and (min-width: 1025px) {
    .titreKaleoticFiche {
        padding: 5% 0 1% 0;
    }
}

.infosPage {
    text-align: center;
}

.photosPage {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1025px) {
    .photosPage {
        flex-direction: row;
    }
}

.divPhotoPage {
    margin: auto;
    margin-bottom: 2%;
}

.photoPageHorizontale {
    width: 80vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageHorizontale {
        width: 30vw;
    }
}

.photoPageVerticale {
    width: 40vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageVerticale {
        width: 18vw;
    }
}

.infosKaleotic {
    color: white;
    text-align: center;
    padding: 5% 0 5% 0;
}

@media screen and (min-width: 1025px) {
    .infosKaleotic {
        padding: 1% 0 1% 0;
    }
}

/* Kaleotic courses ------------------------------------------------------------------ */

.accueilKaleoticCourses {
    min-height: 100vh;
    width: 100%;
    background-color: var(--backgroundKaleotic-color);
    color: white;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
    text-align: -webkit-center;
}

.titreKaleoticCourses {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    padding: 5% 0 0 0;
}

.titreKaleoticCourses2 {
    text-align: center;
    font-family: 'Hind Siliguri', cursive;
    margin: 0;
}


.titreKaleoticCourses3 {
    text-align: center;
    font-family: 'Hind Siliguri', cursive;
    padding: 0% 0 5% 0;
}

.rubriqueKaleotic {
    border-radius: 5px;
    width: 70%;
    height: 10vh;
    margin-left: 5%;
    display: flex;
    margin-bottom: 2%;
    color: white;
    background-color: var(--backgroundKaleotic-color2);
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}


.rubriqueKaleotic:hover {
    color: black;
    text-decoration: none;
    background-color: rgb(214, 208, 208);
}

.titreRubriqueKaleotic {
    margin: auto;
    text-align: center;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: bold;
}
